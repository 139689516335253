import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import {
  getCurrentUser,
} from 'authentication/actions'

import Header from './Header'
import Footer from './Footer'

import Spinner from 'components/Spinner'
import PleaseLogin from 'components/PleaseLogin'
import AccessDenied from 'components/AccessDenied'
import ErrorMessage from 'components/ErrorMessage'
import Modal from '../../modals'
import Marquee from 'components/Marquee'

const PageLayout = ({
  currentUser,
  location,
  children,
  getCurrentUser,
}) => {
  
  useEffect(() => {
    getCurrentUser()
    // $(window).resize(function() {
    //   let e = $(window).width();
    //   e >= 1920 ? $("html").css("font-size", "10px") : e >= 1200 ? $("html").css("font-size", 10 * e / 1920 + "px") : e >= 1100 ? $("html").css("font-size", "10px") : $("html").css("font-size", 10 * e / 1300 + "px")
    // })
    $(window).trigger('resize')
  }, [])

  const renderMainBody = () => {
    if (currentUser.loading) return <Spinner type="dot"/>
    if (!currentUser.can_access) return <AccessDenied />
    // if (!currentUser.login && !currentUser.loading) return <PleaseLogin />
    return children
  }

  return (
    <>
      <Header currentUser={currentUser} />
      <section id="main-body" className={`page-${location.pathname != '/' ? location.pathname.replace(/\//g, '') : ''}`}>
        <>
          {renderMainBody()}
          <Modal />
        </>
      </section>
      <Footer />  
    </>
  )
}

const mapDispatchToProps = {
  getCurrentUser,
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout))