import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const PROCESS = 'PROCESS'
export const PROCESS_ERROR = 'PROCESS_ERROR'
export const PROCESS_SUCCESS = 'PROCESS_SUCCESS'
export const PROCESSING = 'PROCESSING'

export const GET_CONFIDENCE_ERROR = 'GET_CONFIDENCE_ERROR'
export const GET_CONFIDENCE_SUCCESS = 'GET_CONFIDENCE_SUCCESS'

export const ARCHIVE_CONFIDENCE_ERROR = 'ARCHIVE_CONFIDENCE_ERROR'
export const ARCHIVE_CONFIDENCE_SUCCESS = 'ACHIVE_CONFIDENCE_SUCCESS'

export const GET_CONFIDENCES_ERROR = 'GET_CONFIDENCES_ERROR'
export const GET_CONFIDENCES_SUCCESS = 'GET_CONFIDENCES_SUCCESS'

export const SET_CONVERSATION = 'SET_CONVERSATION'

// comment
export const isReporting = () => {
  return {
    type: PROCESSING
  }
}
export const sendConfidence = (formData) => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/send-confidence', 'POST', {
      body: formData
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(sendConfidenceSuccess(response.payload))
        const isReply = formData.get('isReply')
        if (isReply == "pm") {
          // pm replied a thread
          dispatch(getConfidences())
          Swal.fire({
            type: 'success',
            title: '',
            html: 'Gửi thông tin thành công.<br/>Hội thoại đã được chuyển vào mục Archived'
          })
        }
        if (isReply == "anonymous") dispatch(getConfidence(formData))
        if (isReply == "false") {
          // send new confidence
          Swal.fire({
            type: 'success',
            title: '',
            html: 'Gửi thông tin thành công.'
          })
        }
      } else {
        Swal.fire({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(sendConfidenceError(response.payload))
      }
    }).catch(function (err) {
      dispatch(sendConfidenceError({}))
    })
  }
}

export const sendConfidenceSuccess = (response) => {
  return {
    type: PROCESS_SUCCESS,
    payload: response
  }
}

export const sendConfidenceError = (response) => {
  return {
    type: PROCESS_ERROR,
    payload: response
  }
}


export const getConfidence = (formData) => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/my-confidence', 'POST', {
      body: formData
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getConfidenceSuccess(response.payload))
      } else {
        Swal.fire({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(getConfidenceError(response.payload))
      }
    }).catch(function (err) {
      dispatch(getConfidenceError({}))
    })
  }
}
export const getConfidenceSuccess = (response) => {
  return {
    type: GET_CONFIDENCE_SUCCESS,
    payload: response
  }
}
export const getConfidenceError = (response) => {
  return {
    type: GET_CONFIDENCE_ERROR,
    payload: response
  }
}

export const getConfidences = () => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/my-confidences', 'POST', {}).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getConfidencesSuccess(response.payload))
      } else {
        Swal.fire({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(getConfidencesError(response.payload))
      }
    }).catch(function (err) {
      dispatch(getConfidencesError({}))
    })
  }
}
export const getConfidencesSuccess = (response) => {
  return {
    type: GET_CONFIDENCES_SUCCESS,
    payload: response
  }
}
export const getConfidencesError = (response) => {
  return {
    type: GET_CONFIDENCES_ERROR,
    payload: response
  }
}


export const archiveConfidence = (formData) => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/archive-confidence', 'POST', {
      body: formData
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(archiveConfidenceSuccess(response.payload))
        dispatch(getConfidences())
      } else {
        Swal.fire({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(archiveConfidenceError(response.payload))
      }
    }).catch(function (err) {
      dispatch(archiveConfidenceError({}))
    })
  }
}
export const archiveConfidenceSuccess = (response) => {
  return {
    type: ARCHIVE_CONFIDENCE_SUCCESS,
    payload: response
  }
}
export const archiveConfidenceError = (response) => {
  return {
    type: ARCHIVE_CONFIDENCE_ERROR,
    payload: response
  }
}


export const setConversation = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CONVERSATION
    });
  }
}



export const sendInnovation = (formData) => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/send-innovation', 'POST', {
      body: formData
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(sendInnovationSuccess(response.payload))
        const isReply = formData.get('isReply')
        Swal.fire({
          type: 'success',
          title: 'Gửi tin thành công',
          html: 'Team Innovation xin chân thành cám ơn đóng góp của bạn.'
        })
      } else {
        Swal.fire({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(sendInnovationError(response.payload))
      }
    }).catch(function (err) {
      dispatch(sendInnovationError({}))
    })
  }
}

export const sendInnovationSuccess = (response) => {
  return {
    type: PROCESS_SUCCESS,
    payload: response
  }
}

export const sendInnovationError = (response) => {
  return {
    type: PROCESS_ERROR,
    payload: response
  }
}