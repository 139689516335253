import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react'


 
const PleaseLogin = (props) => {
  return (
    <div className="error-message-page">
      <div className="btn-lg btn-danger">
        <div className="error-message">
          Vui lòng <a href="/user/login/1" style={{color: "#fff", textDecoration: "underline" }}>đăng nhập</a> để xem thông tin.
        </div>
      </div>
    </div>

  )

}
export default PleaseLogin
