import request from "lib/request"

export const ADVANCE_SEARCH_REQUEST         = 'ADVANCE_SEARCH_REQUEST'
export const ADVANCE_SEARCH_REQUEST_ERROR   = 'ADVANCE_SEARCH_REQUEST_ERROR'
export const ADVANCE_SEARCH_REQUEST_SUCCESS = 'ADVANCE_SEARCH_REQUEST_SUCCESS'
export const ADVANCE_SEARCH_FETCHING        = 'ADVANCE_SEARCH_FETCHING'

// ADVANCE_SEARCH
export const isSearching = () => {
  return {
    type: ADVANCE_SEARCH_FETCHING,
  }
}
export const advanceSearchAction = (searchParams) => {
  return (dispatch, getState) => {
    dispatch(isSearching());
    request('api/advanced-search', 'POST', {
      body: JSON.stringify(searchParams)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(advanceSearchSuccess(response.payload))
      } else {
        dispatch(advanceSearchError(response.payload))
      }
    }).catch(function(err) {
      dispatch(advanceSearchError({}))
    })
  }
}
export const advanceSearchSuccess = (response) => {
  return {
    type: ADVANCE_SEARCH_REQUEST_SUCCESS,
    payload: response
  }
}

export const advanceSearchError = (response) => {
  return {
    type: ADVANCE_SEARCH_REQUEST_ERROR,
    payload: response
  }
}

