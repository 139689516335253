
import {
  ADVANCE_SEARCH_REQUEST_ERROR,
  ADVANCE_SEARCH_REQUEST_SUCCESS,
  ADVANCE_SEARCH_FETCHING,
} from './actions'

const initialState = {
  searchResult: null,
  searching: false,
}

export default function advanceSearchReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    case ADVANCE_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        searching: false,
        searchResult: payload,
      }
      break;
    case ADVANCE_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        searchResult: null,
        searching: false,
        errorGlobal: ''
      }
      break;  
    case ADVANCE_SEARCH_FETCHING:
      return {
        ...state,
        searching: true
      }
      break;
    default:
      return state
  }
}