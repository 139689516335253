import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const JOIN = 'JOIN'
export const JOIN_ERROR = 'JOIN_ERROR'
export const JOIN_SUCCESS = 'JOIN_SUCCESS'
export const IS_JOINING = 'IS_JOINING'

export const GET_CONTRACT_NUMBERS = 'GET_CONTRACT_NUMBERS'
export const GET_CONTRACT_NUMBERS_ERROR = 'GET_CONTRACT_NUMBERS_ERROR'
export const GET_CONTRACT_NUMBERS_SUCCESS = 'GET_CONTRACT_NUMBERS_SUCCESS'

export const GET_LONG_TERM_CONTRACTS_SUCCESS = 'GET_LONG_TERM_CONTRACTS_SUCCESS'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const GET_STAFFS_LIST_SUCCESS = 'GET_STAFFS_LIST_SUCCESS'

export const GEN_APPENDIX_NUMBER = 'GEN_APPENDIX_NUMBER'
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS'


import { assignTasks } from 'routes/TicketDetail/modules/actions'

//join
export const isJoining = () => {
  return {
    type: IS_JOINING
  }
}

const _afterJoinAction = (formData, response) => {
  let ticketId = response.payload.ticket.id
  let redirectUrl = config.url + '/ticket/' + ticketId
  let text = 'Vui lòng kiểm tra thông tin trong hòm thư của bạn'

  Swal({
    type: 'success',
    title: 'Gửi thông tin thành công',
    text: text,
  }).then((confirmDialogResult) => {
    if (formData.form_id == 21) return null
    if (confirmDialogResult.value) {
      window.location.href = redirectUrl
    } else {
      setTimeout(function () {
        window.location.href = redirectUrl
      }, 3000)
    }
  })
}

export const join = (formData) => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/user/submit-form', 'POST', {
      body: formData instanceof FormData ? formData : JSON.stringify(formData)
    }).then(function (response) {
      // send init tasks
      if (formData.data.initialTasks) {
        const ticketId = response.payload?.ticket?.id
        if (ticketId) {
          dispatch(
            assignTasks({
              ticketId,
              tasks: formData.data.initialTasks,
              shouldAddHistory: false,
              shouldNotifyAssignee: false,
            })
          )
        }
      }
      if (response.status == 'successful') {
        _afterJoinAction(formData, response)
        dispatch(joinSuccess(response))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(joinError(response))
      }
    })
  }
}

export const joinSuccess = (response) => {
  return {
    type: JOIN_SUCCESS,
    payload: response.payload
  }
}

export const joinError = (response) => {
  return {
    type: JOIN_ERROR,
    payload: response.payload
  }
}

export const reopen = (formData) => {
  return (dispatch, getState) => {
    // dispatch(isJoining())
    request('api/ticket/reopen', 'POST', {
      body: JSON.stringify(formData)
    }).then(function (response) {
      if (response.status == 'successful') {
        Swal({
          type: 'success',
          html: 'Ticket reopened successfully'
        })
        // dispatch(joinSuccess(response));
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        // dispatch(joinError(response))
      }
    })
  }
}

export const getContractNumbersByEmail = () => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/user/get-contract-numbers-by-email', 'GET', {
    }).then(function (response) {
      dispatch(getContractNumbersSuccess(response))
    })
  }
}

export const getContractNumbersSuccess = (response) => {
  return {
    type: GET_CONTRACT_NUMBERS_SUCCESS,
    payload: response
  }
}

export const getContractNumbersError = (response) => {
  return {
    type: GET_CONTRACT_NUMBERS_ERROR,
    payload: response
  }
}

export const getLongtermContracts = () => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/long_term_contracts/get-infos', 'GET', {
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getLongtermContractsSuccess(response.payload?.long_term_contract_infos))
      }
    })
  }
}

export const getLongtermContractsSuccess = (response) => {
  return {
    type: GET_LONG_TERM_CONTRACTS_SUCCESS,
    payload: response
  }
}

export const getPartners = () => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/user/get-partners', 'GET', {
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getPartnersSuccess(response.payload?.partners))
      }
    })
  }
}

export const getPartnersSuccess = (response) => {
  return {
    type: GET_PARTNERS_SUCCESS,
    payload: response
  }
}

export const getStaffsList = () => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/user/get-staff-infos').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getStaffsListSuccess(response.payload?.staff_list))
      }
    })
  }
}

export const getStaffsListSuccess = (response) => {
  return {
    type: GET_STAFFS_LIST_SUCCESS,
    payload: response
  }
}

export const genAppendixNumber = (params) => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/long_term_contracts/gen-appendix-number', 'POST', {
      body: JSON.stringify(params)
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(genAppendixNumberSuccess(response.payload?.appendix_number))
      } else {
        if (response.error_code == 'error_params') {
          Swal.fire({
            html: `Không tìm thấy thông tin Hợp đồng`,
            type: 'error'
          })
        } else if (response.error_code == 'error_contract_time') {
          Swal.fire({
            html: `Hợp đồng đã hết hạn`,
            type: 'error'
          })
        }
      }
    })
  }
}

export const genAppendixNumberSuccess = (response) => {
  return {
    type: GEN_APPENDIX_NUMBER,
    payload: response
  }
}

export const getCourses = () => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/course/get-infos', 'GET', {
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getCoursesSuccess(response.payload?.course_infos))
      }
    })
  }
}

export const getCoursesSuccess = (response) => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: response
  }
}