
import {
  TICKET_DETAIL_REQUEST_ERROR,
  TICKET_DETAIL_REQUEST_SUCCESS,
  TICKET_DETAIL_FETCHING,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_ERROR,
  UPDATING_TICKET,
  COMMENT_SUCCESS,
  COMMENT_ERROR,
  COMMENTING,
  REMOVING_WATCHER,
  REMOVEWATCHER_SUCCESS,
  REMOVEWATCHER_ERROR, 
  ADDWATCHER_SUCCESS, 
  ADDWATCHER_ERROR, 
  ADDING_WATCHER
} from './actions'

const initialState = {
  ticket: null,
  error: null,
  loadingTicket: false,
  isProcessingAction: false,
}

export default function ticketReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    // ticket detail
    case TICKET_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        ticket: payload,
        loadingTicket: false,
      }
      break;
    case TICKET_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        ticket: null,
        loadingTicket: false,
        error: payload
      }
      break;
    case TICKET_DETAIL_FETCHING:
      return {
        ...state,
        loadingTicket: true
      }
      break;
    // update ticket
    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        ticket: payload,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case UPDATE_TICKET_ERROR:
      Swal({
        title: 'Update ticket failed',
        html: payload,
        type: 'error',
      })
      return {
        ...state,
        isProcessingAction: false,
      }
      break;
    case UPDATING_TICKET:
      return {
        ...state,
        isProcessingAction: true
      }
      break;  
    // comment ticket
    case COMMENT_SUCCESS:
      return {
        ...state,
        ticket: {
          ...payload,
          tasks: state.ticket.tasks
        },
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case COMMENT_ERROR:
      return {
        ...state,
        isProcessingAction: false,
        ticket: null,
      }
      break;
    case COMMENTING:
      return {
        ...state,
        isProcessingAction: true
      }
      break;    
    // remove watcher
    case REMOVEWATCHER_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case REMOVEWATCHER_ERROR:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: payload.err
      }
      break;
    case REMOVING_WATCHER:
      return {
        ...state,
        isProcessingAction: true
      }
      break;  
    // add watcher
    case ADDWATCHER_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case ADDWATCHER_ERROR:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: payload.err
      }
      break;
    case ADDING_WATCHER:
      return {
        ...state,
        isProcessingAction: true
      }
      break;  
    default:
      return state
  }
}