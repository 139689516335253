import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react'
import cl from 'classnames'

const Marquee = ({message, type, inlineStyle = {}}) => {
  let mMessages = ''
  message.map(x => {mMessages += `<span class="message">${x}</span>`})
  return (
    <div className="alert-message-page">
      <div role='alert' 
        style={inlineStyle}
        className={cl('alert', 
          {'alert-danger': type == 'error'}, 
          {'alert-success': type  == 'success'},
          {'alert-warning': type  == 'warning'},
          )}>
            <div className="container">
              <marquee>
                <div className="messages" dangerouslySetInnerHTML={{ __html: mMessages }}>
                </div>
              </marquee>
            </div>
      </div>
    </div>
  );

}
export default Marquee
