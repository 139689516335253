
import {
  TICKETS_REQUEST_ERROR,
  TICKETS_REQUEST_SUCCESS,
  TICKETS_FETCHING,
  MY_PENDING_SIGNATURE_SUCCESS,
  MY_PENDING_SIGNATURE_ERROR,
  MY_PENDING_SIGNATURE_FETCHING
} from './actions'

const initialState = {
  tickets: null,
  myPendingSignatures: [],
  loadingTickets: false,
}

export default function ticketsReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    case TICKETS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingTickets: false,
        tickets: payload,
      }
      break;
    case TICKETS_REQUEST_ERROR:
      return {
        ...state,
        tickets: null,
        loadingTickets: false,
        errorGlobal: ''
      }
      break;  
    case TICKETS_FETCHING:
      return {
        ...state,
        loadingTickets: true
      }
      break;
    case MY_PENDING_SIGNATURE_SUCCESS:
      return {
        ...state,
        loadingTickets: false,
        myPendingSignatures: payload.pending_files,
      }
      break;
    case MY_PENDING_SIGNATURE_ERROR:
      return {
        ...state,
        loadingTickets: false,
        myPendingSignatures: [],
        errorGlobal: ''
      }
      break;  
    case MY_PENDING_SIGNATURE_FETCHING:
      return {
        ...state,
        loadingTickets: true
      }
      break;  
    default:
      return state
  }
}