import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const COLLABORATORS_REQUEST = 'COLLABORATORS_REQUEST'
export const COLLABORATORS_REQUEST_ERROR = 'COLLABORATORS_REQUEST_ERROR'
export const COLLABORATORS_REQUEST_SUCCESS = 'COLLABORATORS_REQUEST_SUCCESS'
export const COLLABORATORS_FETCHING = 'COLLABORATORS_FETCHING'

export const GET_COLLABORATOR_BY_IDS_SUCCESS = 'GET_COLLABORATOR_BY_IDS_SUCCESS'

// get collaborators
export const isTicketsFetching = () => {
  return {
    type: COLLABORATORS_FETCHING,
  }
}
export const getCollaborators = () => {
  return (dispatch, getState) => {
    dispatch(isTicketsFetching());
    request('api/user/get-collaborators').then(function (response) {
      dispatch(getCollaboratorsSuccess(response.payload))
    }).catch(function (err) {
      dispatch(getCollaboratorsError({}))
    })
  }
}
export const getCollaboratorsSuccess = (response) => {
  return {
    type: COLLABORATORS_REQUEST_SUCCESS,
    payload: response
  }
}

export const getCollaboratorsError = (response) => {
  return {
    type: COLLABORATORS_REQUEST_ERROR,
    payload: response
  }
}


export const getCollaboratorByIds = (collaborator_ids) => {
  return (dispatch, getState) => {
    request('api/user/get-collaborator-by-ids', 'POST', {
      body: JSON.stringify({
        collaborator_ids: collaborator_ids
      })
    }).then(function (response) {
      dispatch(getCollaboratorByIdsSuccess(response))
    })
  }
}
export const getCollaboratorByIdsSuccess = (response) => {
  return {
    type: GET_COLLABORATOR_BY_IDS_SUCCESS,
    payload: response
  }
}