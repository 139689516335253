import React from 'react'
import ScrollArrow from '../../components/ScrollArrow'

class Footer extends React.Component {
  render() {
    return (
      <>
      <div className="no-print">
        <ScrollArrow />
      </div>
      <footer id="footer">
        <div className="wrap-footer text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>Công ty Cổ phần Giải trí và Thể thao điện tử Việt Nam.<br/>Địa chỉ: Tầng 6, Tòa nhà Capital Place, 29 Liễu Giai, Phường Ngọc Khánh, Quận Ba Đình, Thành phố Hà Nội, Việt Nam</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </>
    )
  }
}

export default Footer
