
import {
  REPORT_ADMIN_GAME_REQUEST_ERROR,
  REPORT_ADMIN_GAME_REQUEST_SUCCESS,
  REPORT_ADMIN_GAME_FETCHING,
} from './actions'

const initialState = {
  searchResult: {},
  searching: false,
}

export default function reportAdminGameReducer(state = initialState, { type, payload } = action) {
  const pic = payload?.pic
  switch (type) {
    case REPORT_ADMIN_GAME_REQUEST_SUCCESS:
      return {
        ...state,
        searching: false,
        searchResult: {
          ...state.searchResult,
          [pic]: [...payload.response],
        },
      }
      break;
    case REPORT_ADMIN_GAME_REQUEST_ERROR:
      return {
        ...state,
        searching: false,
        errorGlobal: ''
      }
      break;  
    case REPORT_ADMIN_GAME_FETCHING:
      return {
        ...state,
        searching: true
      }
      break;
    default:
      return state
  }
}