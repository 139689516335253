import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { showModal } from 'modals'

import lib from 'lib/commons'
import config from 'config/app'
import Alert from 'components/Alert'
import moment from 'moment'

const Header = ({ currentUser: {
  isNewStaffTicketId,
  login,
  loading,
  user,
  confidences,
  tasks,
  pending_my_approval: pendingMyApprovalCnt = 0
} }) => {
  let incompletedTasks = tasks.filter(x => x.status == 'incomplete')
  let incompletedTasksCnt = incompletedTasks.length
  const _renderLoginBtn = () => {
    if (!login) {
      if (!loading) return <a href="/user/login" className="login-url">Login</a>
    }
    return (
      <div className="login-section">
        <span className="avatar"><img src={user.avatar}></img> </span>
        <NavLink to="/profile" className="username">
          <span className="login-url">{user.name}, </span>
        </NavLink>
        <a href="/user/logout" className="login-url">
          &nbsp;Logout</a>
      </div>
    )
  }
  const activeConfidencesCnt = confidences?.filter(x => x.active).length
  // console.log('confidences', activeConfidencesCnt)

  const _renderNotificationBoard = () => {
    return `
        <table class="notification-table">
          <tbody>
            <tr>
              <td>Ticket cần phê duyệt</td>
              <td style="color:red; font-weight: bold">${pendingMyApprovalCnt}</td>
              <td><a href="/tickets">Thực hiện</a></td>
            </tr>
            <tr>
              <td>Công tác phí cần submit</td>
              <td style="color:red; font-weight: bold">${incompletedTasksCnt}</td>
              <td><a href="/tasks">Thực hiện</a></td>
            </tr>
          </tbody>
        </table>
      `
  }

  useEffect(() => {
    setTimeout(() => {
      // if ([config.TRAN_TRUNG_KIEN].includes(user.email)) return false
      if (pendingMyApprovalCnt > 0 || incompletedTasksCnt > 0) {
        const seenKey = `seen_pending_my_approval_${moment().format('YYYY-MM-DD')}`
        const seen = localStorage.getItem(seenKey)
        if (seen) return
        Swal({
          type: 'warning',
          title: 'Lưu ý',
          html: _renderNotificationBoard(),
          customClass: 'homepage-popup'
        })
        localStorage.setItem(seenKey, moment().format('HH:mm:ss'))
      }
    }, [])
  }, [incompletedTasksCnt])

  return (
    <header id="header">
      <nav className=" navbar navbar-expand-lg navbar-light bg-light">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">Home</NavLink>
            </li>
            {login &&
              <li className="nav-item">
                <NavLink to="/article/332" className="nav-link">Core Values</NavLink>
              </li>
            }
            {login &&
              <li className="nav-item">
                <NavLink to="/tickets" className="nav-link">Tickets</NavLink>
                {pendingMyApprovalCnt > 0 && <span className="badge">{pendingMyApprovalCnt}</span>}
              </li>
            }
            {login &&
              <li className="nav-item">
                <NavLink to="/pending_my_signature" className="nav-link">PendingMySignature</NavLink>
              </li>
            }
            {tasks.length > 0 &&
              <li className="nav-item">
                <NavLink to="/tasks" className="nav-link">CôngTácPhí</NavLink>
                {incompletedTasks.length > 0 && <span className="badge">{incompletedTasks.length}</span>}
              </li>
            }
            <li className="nav-item">
              <NavLink to="/interns" className="nav-link">CTV</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/we_listen" className="nav-link">WeListen</NavLink>
              {activeConfidencesCnt > 0 && <span className="badge">{activeConfidencesCnt}</span>}
            </li> */}
            {login &&
              <li className="nav-item">
                <NavLink to="/contact_us" className="nav-link">ContactUs</NavLink>
              </li>
            }
            {isNewStaffTicketId &&
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={() => window.location = '/'}>Onboarding Checkpoints</a>
              </li>
            }
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                More
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                {login &&
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://docs.google.com/spreadsheets/d/1F0Zi20gu-3jF4msWtWK7Hiqy-Ij9vSfdWIRvA4Xg8Ks/edit#gid=673914557">Feedback</a>
                  </li>
                }
                {user && config.APPROVE_CENTER_USERS.includes(user.email) &&
                  <li className="nav-item">
                    <NavLink to="/approve_center" className="nav-link">Approve</NavLink>
                  </li>
                }
                {user && config.FORM_19_USERS.includes(user.email) &&
                  <li className="nav-item">
                    <NavLink to="/form/19" className="nav-link">CôngTácDàiHạn</NavLink>
                  </li>
                }
                <div class="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink to="/profile" className="nav-link">LịchNghỉPhép</NavLink>
                </li>
                {user && [config.TRAN_TRUNG_KIEN, 'manhlinh.nguyen@garena.vn',
                  'vananh.tran@garena.vn', 'thutrang.pham@garena.vn',
                  'thibinh.trinh@garena.vn'].includes(user.email) &&
                  <li className="nav-item">
                    <NavLink to="/report_datcom_giaidau" className="nav-link">Quản lý cơm giải đấu</NavLink>
                  </li>
                }
                {user && [config.VU_CHI_CONG, config.TRAN_TRUNG_KIEN, 'dinhphuc.luu@garena.vn'].includes(user.email) &&
                  <li className="nav-item">
                    <NavLink to="/report_feedback_code" className="nav-link">ReportMicrosite</NavLink>
                  </li>
                }
              </div>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">
            {_renderLoginBtn()}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header