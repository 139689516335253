import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import {
  sendConfidence, getConfidence, getConfidences, archiveConfidence, setConversation
} from 'routes/AskMeAnything/modules/actions.js'

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const SEARCH         = 'SEARCH'
export const SEARCH_ERROR   = 'SEARCH_ERROR'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const IS_SEARCHING   = 'IS_SEARCHING'

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response))
        dispatch(getConfidences())
      } else {
        dispatch(getCurrentUserError(response))
      }
    }).catch(function(err) {
      dispatch(getCurrentUserError({}))
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response
  }
}


//search
export const isSearching = () => {
  return {
    type: IS_SEARCHING
  }
}

export const search = (query) => {
  return (dispatch, getState) => {
    dispatch(isSearching())
    request('api/user/search', 'POST', {
      body: JSON.stringify(query)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(searchSuccess(response));
      } else {
        Swal.fire({
          title: 'Thông báo',
          'html': '<p>' + lib.showError(response.error_code) + '</p>',
          confirmButtonText: 'Xác nhận',
          showCancelButton: false,
          showCloseButton: true,
          customClass: {
            popup: 'popup-small'
          },
        })
        dispatch(searchError(response))
      }
    })
  }
}

export const searchSuccess = (response) => {
  return {
    type: SEARCH_SUCCESS,
    payload: response.ret_val
  }
}

export const searchError = (response) => {
  return {
    type: SEARCH_ERROR,
    payload: response.payload
  }
}