
import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  SEARCH_SUCCESS,
  SEARCH_ERROR,
  IS_SEARCHING,
} from './actions'

import {
  GET_CONFIDENCES_SUCCESS,
} from 'routes/AskMeAnything/modules/actions'

import config from 'config/app'

const initialState = {
  loading: false,
  login: false,
  user: {},
  isInternalIP: false,
  pending_my_approval: 0,
  confidences: [],
  banners: [],
  can_access: true,
  nodes: config.DEFAULT_NODES,
  form_nodes: config.DEFAULT_FORM_NODES,
  isSearching: false,
  tasks: [],
  adminGames: [],
  currentTime: null,
  registrations: {
    total: 0,
    records: [],
  },
  search_results: {
    total: 0,
    data: []
  },
  isNewStaffTicketId: null
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case GET_CONFIDENCES_SUCCESS:
      return {
        ...state,
        confidences: payload
      }
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        isInternalIP: payload.isInternalIP,
        pending_my_approval: payload.pending_my_approval,
        nodes: payload.nodes,
        form_nodes: payload.form_nodes,
        search_results: {
          total: 0,
          data: []
        },
        tasks: payload.tasks || [],
        adminGames: payload.admin_game || [],
        banners: payload.banners || [],
        registrations: {
          records: payload.registrations.records,
          total: payload.registrations.total
        },
        currentTime: payload.current_time,
        isNewStaffTicketId: payload.is_new_staff_ticket_id,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        login: false,
        loading: true,
        isInternalIP: payload.payload?.isInternalIP,
        can_access: payload.error_code == 'access_denied' ? false : true,
        loading: false,
        errorGlobal: 'Không có thông tin user'
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        login: false,
        loading: true
      }
      break;

    
    //SEARCH
    case SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        search_results: payload
      }
      break;
    case SEARCH_ERROR:
      return {
        ...state,
        isSearching: false,
        search_results: {
          total: 0,
          search_results: []
        },
      }
      break;
    case IS_SEARCHING:
      return {
        ...state,
        isSearching: true,
        search_results: {
          total: 0,
          search_results: []
        },
      }
      break;
  
    default:
      return state
  }
}
