import request from "lib/request"

export const MY_PENDING_SIGNATURE_SUCCESS = 'MY_PENDING_SIGNATURE_SUCCESS'
export const MY_PENDING_SIGNATURE_ERROR   = 'MY_PENDING_SIGNATURE_ERROR'
export const MY_PENDING_SIGNATURE_FETCHING = 'MY_PENDING_SIGNATURE_FETCHING'

// my pending signature
export const isPendingSignatureFetching = () => {
  return {
    type: MY_PENDING_SIGNATURE_FETCHING,
  }
}
export const getMyPendingSignatureFiles = ({fromDate, toDate}) => {
  return (dispatch, getState) => {
    dispatch(isPendingSignatureFetching());
    request('api/user/get-my-pending-signature-files', 'POST', {
      body: JSON.stringify({
        fromDate,
        toDate,
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getMyPendingSignatureSuccess(response.payload))
      } else {
        dispatch(getMyPendingSignatureError(response.payload))
      }
    }).catch(function(err) {
      dispatch(getMyPendingSignatureError({}))
    })
  }
}
export const getMyPendingSignatureSuccess = (response) => {
  return {
    type: MY_PENDING_SIGNATURE_SUCCESS,
    payload: response
  }
}
export const getMyPendingSignatureError = (response) => {
  return {
    type: MY_PENDING_SIGNATURE_ERROR,
    payload: response
  }
}