import React, { useEffect, Suspense } from 'react'
import { Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch } from 'react-router'
import { createBrowserHistory as createHistory } from 'history'

import { updateLocation } from 'store/location'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const history = createHistory();

import MainLayout from 'layouts/PageLayout/PageLayout'
import Loading from 'components/Loading'

const Home = React.lazy(() => import('routes/Home'))
const SearchResult = React.lazy(() => import('routes/SearchResult'))
const Article = React.lazy(() => import('routes/Article'))
const Form = React.lazy(() => import('routes/Form'))
const AccessDenied = React.lazy(() => import('components/AccessDenied'))
const Tickets = React.lazy(() => import('routes/Tickets'))
const Tasks = React.lazy(() => import('routes/Tasks'))
const Interns = React.lazy(() => import('routes/Interns'))
const TicketDetail = React.lazy(() => import('routes/TicketDetail'))
const TicketEdit = React.lazy(() => import('routes/TicketEdit'))
const TaskDetail = React.lazy(() => import('routes/TaskDetail'))
const InternDetail = React.lazy(() => import('routes/InternDetail'))
const ApproveCenter = React.lazy(() => import('routes/ApproveCenter'))
const Profile = React.lazy(() => import('routes/Profile'))
const AdvanceSearch = React.lazy(() => import('routes/AdvanceSearch'))
const ReportFacebookGroup = React.lazy(() => import('routes/ReportFacebookGroup'))
const AskMeAnything = React.lazy(() => import('routes/AskMeAnything'))
const ContactUs = React.lazy(() => import('routes/ContactUs'))
const SearchTicket = React.lazy(() => import('routes/SearchTicket'))
const ReportAdminGame = React.lazy(() => import('routes/ReportAdminGame'))
const PendingMySignature = React.lazy(() => import('routes/PendingMySignature'))
const ReportFeedbackCode = React.lazy(() => import('routes/ReportFeedbackCode'))
const ReportDatcomGiaidau = React.lazy(() => import('routes/ReportDatcomGiaidau'))

const App = ({ store }) => {

  useEffect(() => {
    history.listen(updateLocation(store))
  }, [])

  return (
    <Provider store={store}>
      <Router
        history={history}
        onUpdate={() => {
          window.scrollTo(0, 0)
        }}>
        <Suspense fallback={<Loading />}>
          <MainLayout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/article/:id" component={Article} />
              <Route path="/form/:id" component={Form} />
              <Route path="/search_result" component={SearchResult} />
              <Route path="/access_denied" component={AccessDenied} />
              <Route path="/ticket/edit/:id" component={TicketEdit} />
              <Route path="/ticket/:id" component={TicketDetail} />
              <Route exact path="/tickets" component={Tickets} />
              <Route path="/task/:id" component={TaskDetail} />
              <Route exact path="/tasks" component={Tasks} />
              <Route path="/intern/:id" component={InternDetail} />
              <Route exact path="/interns" component={Interns} />
              <Route path="/approve_center" component={ApproveCenter} />
              <Route path="/profile" component={Profile} />
              <Route exact path="/advance_search" component={AdvanceSearch} />
              <Route path="/report_facebook_group" component={ReportFacebookGroup} />
              <Route path="/we_listen" component={AskMeAnything} />
              <Route exact path="/contact_us" component={ContactUs} />
              <Route exact path="/search_ticket" component={SearchTicket} />
              <Route exact path="/report_admin_game" component={ReportAdminGame} />
              <Route exact path="/report_feedback_code" component={ReportFeedbackCode} />
              <Route exact path="/report_datcom_giaidau" component={ReportDatcomGiaidau} />
              <Route exact path="/pending_my_signature" component={PendingMySignature} />
            </Switch>
          </MainLayout>
        </Suspense>
      </Router>
    </Provider>
  )
}

export default App