import ReactDOM from 'react-dom'
import React, { useState, useEffect } from 'react'

const genId = mType => mType + Date.now() + Math.floor(Math.random() * 1000)

const store = {
  pushModal: null,
  removeModal: null,
  typeMap: new Map(),
}

export const modal = mType => comp => {
  Object.defineProperty(comp, 'name', { value: mType })
  store.typeMap.set(mType, comp)
  return comp
}

export const showModal = (mType, data) => {
  if (!store.pushModal) throw Error(`please init Modal component`)
  if (!store.typeMap.has(mType)) throw Error(`please import modal type ${mType}`)
  return new Promise((resolve, reject) => store.pushModal({ id: genId(mType), mType, data, resolve, reject }))
}

class ErrorBoundary extends React.PureComponent {
  state = { hasError: false }

  componentDidCatch (error) {
    this.setState({ hasError: true })
    this.props.reject(error)
  }

  render () {
    const { component, reject, ...props } = this.props
    return !this.state.hasError ? React.createElement(component, props) : null
  }
}

const ModalChild = React.memo(({ id, mType, data, resolve, reject }) => (
  <ErrorBoundary
    component={store.typeMap.get(mType)}
    reject={err => {
      store.removeModal(id)
      if (reject instanceof Function) reject(err)
    }}
    close={value => evt => {
      store.removeModal(id)
      if (resolve instanceof Function) resolve(value)
    }}
    data={data}
  />
))

const Modal = ({ container, component, ...props }) => {
  const [modals, setModals] = useState([])

  useEffect(() => {
    store.pushModal = item => setModals(arr => [...arr, item])
    store.removeModal = id => setModals(arr => arr.filter(item => item.id !== id))
    return () => { store.pushModal = store.removeModal = null }
  }, [])

  let children = modals.map(modal => <ModalChild key={modal.id} {...modal} />)
  if (component) children = React.createElement(component, props, children)

  return container ? ReactDOM.createPortal(children) : children
}

export default React.memo(Modal)
