
import {
  COLLABORATORS_REQUEST_ERROR,
  COLLABORATORS_REQUEST_SUCCESS,
  COLLABORATORS_FETCHING,
} from './actions'

const initialState = {
  contactUs: [],
  loadingCollaborators: false,
}

export default function contactUsReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    case COLLABORATORS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCollaborators: false,
        contactUs: payload,
      }
      break;
    case COLLABORATORS_REQUEST_ERROR:
      return {
        ...state,
        contactUs: [],
        loadingCollaborators: false,
        errorGlobal: ''
      }
      break;
    case COLLABORATORS_FETCHING:
      return {
        ...state,
        loadingCollaborators: true
      }
      break;
    default:
      return state
  }
}