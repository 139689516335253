export default {
  CONTRACT_NODES: [
    {
      "node_id": 1,
      "is_root": true,
      "is_leaf": false,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "Bạn muốn hợp đồng cho loại công việc nào",
      "answer": "",
      "leaf_title_23": "",
      "leaf_title_html": "",
      "leaf_description_html": "",
      "link": "",
      "parent": null,
      "path": [1],
      "children": [2,3,4,5,6,7,8,9,10,11,12]
    },
    {
      "node_id": 2,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng cung ứng dịch vụ nguyên tắc dài hạn",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_01_Ver04_hop_dong_dich_vu_master_agreement.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_01_Ver04_hop_dong_dich_vu_master_agreement.docx\">tại đây</a></p>",
      "leaf_description_html": "1. Vẽ/Sáng tác tranh/Chụp hình/Cosplay/Thiết kế trang phục/Thiết kế cúp <br/>\n2. Làm clip, làm video, video reaction<br/>\n3. Ghi âm <br/>\n4. Stream <br/>\n5. Sáng tác lời bài hát (sáng tác mới hoặc dựa trên lời hiện có)<br/>\n6. Tạo và đăng bài viết trên mạng xã hội (Facebook fanpage...), đặt bài báo giấy, báo điện tử <br/>\n7. Đặt Người nổi tiếng (KOLs) tham gia sự kiện, quay phim, chụp hình, làm video, đăng bài lên trang cá nhân<br/>\n8. Makeup, Bình luận viên, Dẫn chương trình (MC)<br/>",
      "link": "https://cdn.helper.garena.vn/docs/legal/Mau_so_01_Ver04_hop_dong_dich_vu_master_agreement.docx",
      "parent": 1,
      "path": [1,2],
      "children": []
    },
    {
      "node_id": 3,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": true,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng cung ứng dịch vụ theo từng vụ việc",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_02_Ver04_hop_dong_dich_vu_case_by_case.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_02_Ver04_hop_dong_dich_vu_case_by_case.docx\">tại đây</a></p>",
      "leaf_description_html": "1. Vẽ/Sáng tác tranh/Chụp hình/Cosplay/Thiết kế trang phục/Thiết kế cúp <br/>\n2. Làm clip, làm video, video reaction<br/>\n3. Ghi âm <br/>\n4. Stream <br/>\n5. Sáng tác lời bài hát (sáng tác mới hoặc dựa trên lời hiện có)<br/>\n6. Tạo và đăng bài viết trên mạng xã hội (Facebook fanpage...), đặt bài báo giấy, báo điện tử <br/>\n7. Đặt Người nổi tiếng (KOLs) tham gia sự kiện, quay phim, chụp hình, làm video, đăng bài lên trang cá nhân<br/>\n8. Makeup, Bình luận viên, Dẫn chương trình (MC)<br/>",
      "link": "https://cdn.helper.garena.vn/docs/legal/Mau_so_02_Ver04_hop_dong_dich_vu_case_by_case.docx",
      "parent": 1,
      "path": [1,3],
      "children": []
    },
    {
      "node_id": 4,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng tham gia giải đấu",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form3_Hop-dong-tham-gia-giai-dau.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form3_Hop-dong-tham-gia-giai-dau.docx\">tại đây</a></p>",
      "leaf_description_html": "Áp dụng đối với các đội tuyển/ cá nhân tham gia thi đấu trong các giải đấu/sự kiện của các game",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form3_Hop-dong-tham-gia-giai-dau.docx",
      "parent": 1,
      "path": [1,4],
      "children": []
    },
    {
      "node_id": 5,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng mua bán hàng hoá dịch vụ",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form4_Hop-dong-mua-ban.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form4_Hop-dong-mua-ban.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form4_Hop-dong-mua-ban.docx",
      "parent": 1,
      "path": [1,5],
      "children": []
    },
    {
      "node_id": 6,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng Cộng tác viên hỗ trợ giải đấu, sự kiện ",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_5.rar\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Mau_so_5.rar\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/Mau_so_5.rar",
      "parent": 1,
      "path": [1,6],
      "children": []
    },
    {
      "node_id": 7,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng đào tạo siêu sao",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form8_Hop-dong-Dao-tao-sieu-sao.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form8_Hop-dong-Dao-tao-sieu-sao.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form8_Hop-dong-Dao-tao-sieu-sao.docx",
      "parent": 1,
      "path": [1,7],
      "children": []
    },
    {
      "node_id": 8,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Đơn đặt hàng",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form6-Don-dat-hang.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng thực hiện tại <a href=\"https://gigi.garena.vn/form/28\">tại đây</a></p>",
      "leaf_description_html": "Áp dụng khi phát sinh các Đơn đặt hàng dịch vụ của các Hợp đồng nguyên tắc/ dài hạn đã ký với đối tác",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form6-Don-dat-hang.docx",
      "parent": 1,
      "path": [1,8],
      "children": []
    },
    {
      "node_id": 9,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Phụ lục sửa đổi, bổ sung hợp đồng",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form5_Phu-luc-sua-doi-bo-sung-HD.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng thực hiện tại <a href=\"https://gigi.garena.vn/form/28\">tại đây</a></p>",
      "leaf_description_html": "Áp dụng trong trường hợp có sự thay đổi: <br/>khối lượng công việc, đơn giá, tổng phí dịch vụ, <br/>thời hạn hoàn thành, nội dung công việc, điều khoản về thanh toán, <br/>hoặc các quyền và nghĩa vụ pháp lý <br/>giữa các Bên trong quá trình thực hiện Hợp đồng đã ký",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form5_Phu-luc-sua-doi-bo-sung-HD.docx",
      "parent": 1,
      "path": [1,9],
      "children": []
    },
    {
      "node_id": 10,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "",
      "answer": "Bản cam kết ba bên",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form10_Ban-cam-ket-ba-ben.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form10_Ban-cam-ket-ba-ben.docx\">tại đây</a></p>",
      "leaf_description_html": "Áp dụng trong trường hợp đối tác là công ty hoặc cá nhân đại diện <br/>hoặc quản lý trực tiếp của KOL <br/>hoặc người cung cấp dịch vụ của Hợp đồng <br/>(không áp dụng trong trường hợp ký hợp đồng với booking agency)",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form10_Ban-cam-ket-ba-ben.docx",
      "parent": 1,
      "path": [1,10],
      "children": []
    },
    {
      "node_id": 11,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "",
      "answer": "Biên bản nghiệm thu",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form7_Bien-ban-nghiem-thu-byFIN.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Form7_Bien-ban-nghiem-thu-byFIN.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/Form7_Bien-ban-nghiem-thu-byFIN.docx",
      "parent": 1,
      "path": [1,11],
      "children": []
    },
    {
      "node_id": 12,
      "is_root": false,
      "is_leaf": false,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "Vui lòng chọn loại hợp đồng ",
      "answer": "Các loại hợp đồng khác",
      "leaf_title_23": "",
      "leaf_title_html": "",
      "leaf_description_html": "",
      "link": "",
      "parent": 1,
      "path": [1,12],
      "children": [13,14,15,16,19,20,21]
    },
    {
      "node_id": 13,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng chuyển nhượng quyền tác giả",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Hop_dong_chuyen_nhuong_quyen_tac_gia.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/Hop_dong_chuyen_nhuong_quyen_tac_gia.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/Hop_dong_chuyen_nhuong_quyen_tac_gia.docx",
      "parent": 12,
      "path": [1,12,13],
      "children": []
    },
    {
      "node_id": 14,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng cấp quyền sử dụng tác phẩm âm nhạc",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/hop_dong_cap_quyen_am_nhac.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/hop_dong_cap_quyen_am_nhac.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/hop_dong_cap_quyen_am_nhac.docx",
      "parent": 12,
      "path": [1,12,14],
      "children": []
    },
    {
      "node_id": 15,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng thuê thiết bị - Dịch vụ",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/hop_dong_thue_thiet_bi.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/hop_dong_thue_thiet_bi.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/hop_dong_thue_thiet_bi.docx",
      "parent": 12,
      "path": [1,12,15],
      "children": []
    },
    {
      "node_id": 16,
      "is_root": false,
      "is_leaf": false,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "Chọn ngôn ngữ",
      "answer": "Thoả thuận bảo mật thông tin",
      "leaf_title_23": "",
      "leaf_title_html": "",
      "leaf_description_html": "",
      "link": "",
      "parent": 12,
      "path": [1,12,16],
      "children": [17,18]
    },
    {
      "node_id": 17,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Tiếng Anh",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/nda_eng.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/nda_eng.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/nda_eng.docx",
      "parent": 16,
      "path": [1,12,16,17],
      "children": []
    },
    {
      "node_id": 18,
      "is_root": false,
      "is_leaf": true,
      "show_only": true,
      "show_create_contract": false,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Tiếng Việt",
      "leaf_title_23": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/nde_vie.docx\">tại đây</a></p>",
      "leaf_title_html": "<p>Vui lòng Download tài liệu <a href=\"https://cdn.helper.garena.vn/docs/legal/nde_vie.docx\">tại đây</a></p>",
      "leaf_description_html": "",
      "link": "https://cdn.helper.garena.vn/docs/legal/nde_vie.docx",
      "parent": 16,
      "path": [1,12, 16,18],
      "children": []
    },
    {
      "node_id": 19,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": false,
      "show_create_bbnt": false,
      "question": "",
      "answer": "Hợp đồng chưa có mẫu",
      "leaf_title_23": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_title_html": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_description_html": "",
      "link": "",
      "parent": 12,
      "path": [1,12,19],
      "children": []
    },
    {
      "node_id": 20,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": true,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng không phát sinh chi phí hoặc mang tính chất bảo mật cao",
      "leaf_title_23": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_title_html": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_description_html": "",
      "link": "",
      "parent": 12,
      "path": [1,12,20],
      "children": []
    },
    {
      "node_id": 21,
      "is_root": false,
      "is_leaf": true,
      "show_only": false,
      "show_create_contract": true,
      "show_create_bbnt": true,
      "question": "",
      "answer": "Hợp đồng có đại diện ký ",
      "leaf_title_23": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_title_html": "<p>Vui lòng liên hệ Legal (anhtuan.nguyen_legal@garena.vn) để được hướng dẫn</p>",
      "leaf_description_html": "",
      "link": "",
      "parent": 12,
      "path": [1,12,21],
      "children": []
    }
   ]
}