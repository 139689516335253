
import {
  REPORT_GROUP_SUCCESS,
  REPORT_GROUP_ERROR,
  REPORTING,
} from './actions'

const initialState = {
  isProcessingAction: false,
}

export default function reportFacebookReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    // report group facebook
    case REPORT_GROUP_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case REPORT_GROUP_ERROR:
      return {
        ...state,
        isProcessingAction: false,
      }
      break;
    case REPORTING:
      return {
        ...state,
        isProcessingAction: true
      }
      break;    
    default:
      return state
  }
}