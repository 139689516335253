import request from "lib/request"
import config from "config/app"

export const REPORT_GROUP = 'REPORT_GROUP'
export const REPORT_GROUP_ERROR = 'REPORT_GROUP_ERROR'
export const REPORT_GROUP_SUCCESS = 'REPORT_GROUP_SUCCESS'
export const REPORTING = 'REPORTING'

// comment
export const isReporting = () => {
  return {
    type: REPORTING
  }
}
export const reportGroup = (formData) => {
  return (dispatch, getState) => {
    dispatch(isReporting());
    request('api/user/send-fb-report', 'POST', {
      body: JSON.stringify(formData)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(reportSuccess(response.payload))
        Swal.fire({
          type: 'success',
          title: 'Gửi báo cáo thành công',
          html: 'Bạn đừng quên gửi báo cáo trước 18:00 mỗi thứ 6 hàng tuần nhé!'
        })
      } else {
        Swal.fire({
          type: 'error',
          html: 'Có lỗi xảy ra. Vui lòng thử lại!'
        })
        dispatch(reportError(response.payload))
      }
    }).catch(function(err) {
      dispatch(reportError({}))
    })
  }
}
export const reportSuccess = (response) => {
  return {
    type: REPORT_GROUP_SUCCESS,
    payload: response
  }
}

export const reportError = (response) => {
  return {
    type: REPORT_GROUP_ERROR,
    payload: response
  }
}