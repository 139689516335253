
import {
  JOIN_ERROR,
  JOIN_SUCCESS,
  IS_JOINING,
  GET_CONTRACT_NUMBERS_ERROR,
  GET_CONTRACT_NUMBERS_SUCCESS,
  GET_LONG_TERM_CONTRACTS_SUCCESS,
  GET_PARTNERS_SUCCESS,
  GET_STAFFS_LIST_SUCCESS,
  GEN_APPENDIX_NUMBER,
  GET_COURSES_SUCCESS
} from './actions'

const initialState = {
  isJoining: false,
  formResponse: null,
  contractNumberList: [],
  longtermContractInfos: [],
  partners: [],
  staffEmailList: [],
  appendixNumber: null,
  courses: [],
}

export default function formReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    case JOIN_SUCCESS:
      return {
        ...state,
        formResponse: payload,
        isJoining: false,
      }
      break;
    case JOIN_ERROR:
      return {
        ...state,
        isJoining: false,
      }
      break;
    case GET_CONTRACT_NUMBERS_SUCCESS:
      return {
        ...state,
        contractNumberList: payload,
        isJoining: false,
      }
      break;
    case GET_LONG_TERM_CONTRACTS_SUCCESS:
      return {
        ...state,
        longtermContractInfos: payload,
        isJoining: false,
      }
      break;
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload,
        isJoining: false,
      }
      break;
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: payload,
        isJoining: false,
      }
      break;
    case GET_STAFFS_LIST_SUCCESS:
      return {
        ...state,
        staffEmailList: payload,
        isJoining: false,
      }
      break;
    case GEN_APPENDIX_NUMBER:
      return {
        ...state,
        appendixNumber: payload,
        isJoining: false,
      }
    case GET_CONTRACT_NUMBERS_ERROR:
      return {
        ...state,
        contractNumberList: [],
        isJoining: false,
      }
      break;
    case IS_JOINING:
      return {
        ...state,
        isJoining: true
      }
      break;
    default:
      return state
  }
}