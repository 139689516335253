import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const TICKETS_REQUEST         = 'TICKETS_REQUEST'
export const TICKETS_REQUEST_ERROR   = 'TICKETS_REQUEST_ERROR'
export const TICKETS_REQUEST_SUCCESS = 'TICKETS_REQUEST_SUCCESS'
export const TICKETS_FETCHING        = 'TICKETS_FETCHING'

export const MY_PENDING_SIGNATURE_SUCCESS = 'MY_PENDING_SIGNATURE_SUCCESS'
export const MY_PENDING_SIGNATURE_ERROR   = 'MY_PENDING_SIGNATURE_ERROR'
export const MY_PENDING_SIGNATURE_FETCHING = 'MY_PENDING_SIGNATURE_FETCHING'

// tickets
export const isTicketsFetching = () => {
  return {
    type: TICKETS_FETCHING,
  }
}
export const getTickets = ({start_time, end_time}) => {
  return (dispatch, getState) => {
    dispatch(isTicketsFetching());
    request('api/ticket/get', 'POST', {
      body: JSON.stringify({
        start_time,
        end_time,
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getTicketsSuccess(response.payload))
      } else {
        dispatch(getTicketsError(response.payload))
      }
    }).catch(function(err) {
      dispatch(getTicketsError({}))
    })
  }
}
export const getTicketsSuccess = (response) => {
  return {
    type: TICKETS_REQUEST_SUCCESS,
    payload: response
  }
}

export const getTicketsError = (response) => {
  return {
    type: TICKETS_REQUEST_ERROR,
    payload: response
  }
}


// my pending signature
export const isPendingSignatureFetching = () => {
  return {
    type: MY_PENDING_SIGNATURE_FETCHING,
  }
}
export const getMyPendingSignatureFiles = ({fromDate, toDate}) => {
  return (dispatch, getState) => {
    dispatch(isPendingSignatureFetching());
    request('api/user/get-my-pending-signature-files', 'POST', {
      body: JSON.stringify({
        fromDate,
        toDate,
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getMyPendingSignatureSuccess(response.payload))
      } else {
        dispatch(getMyPendingSignatureError(response.payload))
      }
    }).catch(function(err) {
      dispatch(getMyPendingSignatureError({}))
    })
  }
}
export const getMyPendingSignatureSuccess = (response) => {
  return {
    type: MY_PENDING_SIGNATURE_SUCCESS,
    payload: response
  }
}
export const getMyPendingSignatureError = (response) => {
  return {
    type: MY_PENDING_SIGNATURE_ERROR,
    payload: response
  }
}