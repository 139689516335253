import { combineReducers } from 'redux'
import locationReducer from './location'
import currentUserReducer from '../authentication/reducer'
import ticketReducer from '../routes/TicketDetail/modules/reducer'
import ticketsReducer from '../routes/Tickets/modules/reducer'
import formReducer from '../routes/Form/modules/reducer'
import reportFacebookReducer from '../routes/ReportFacebookGroup/modules/reducer'
import askMeAnythingReducer from '../routes/AskMeAnything/modules/reducer'
import advanceSearchReducer from '../routes/AdvanceSearch/modules/reducer'
import reportAdminGameReducer from '../routes/ReportAdminGame/modules/reducer'
import pendingMySignatureReducer from '../routes/PendingMySignature/modules/reducer'
import collaboratorsReducer from '../routes/Interns/modules/reducer'
import contactUsReducer from '../routes/ContactUs/modules/reducer'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    currentUser: currentUserReducer,
    location: locationReducer,
    ticket: ticketReducer,
    tickets: ticketsReducer,
    forms: formReducer,
    reportFacebook: reportFacebookReducer,
    askMeAnything: askMeAnythingReducer,
    advanceSearch: advanceSearchReducer,
    reportAdminGame: reportAdminGameReducer,
    pendingMySignature: pendingMySignatureReducer,
    collaborators: collaboratorsReducer,
    contactUsReducer: contactUsReducer,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
