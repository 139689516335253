import request from "lib/request"

export const REPORT_ADMIN_GAME_REQUEST         = 'REPORT_ADMIN_GAME_REQUEST'
export const REPORT_ADMIN_GAME_REQUEST_ERROR   = 'REPORT_ADMIN_GAME_REQUEST_ERROR'
export const REPORT_ADMIN_GAME_REQUEST_SUCCESS = 'REPORT_ADMIN_GAME_REQUEST_SUCCESS'
export const REPORT_ADMIN_GAME_FETCHING        = 'REPORT_ADMIN_GAME_FETCHING'

// REPORT_ADMIN_GAME
export const isSearching = () => {
  return {
    type: REPORT_ADMIN_GAME_FETCHING,
  }
}
export const reportAdminGameAction = (searchParams) => {
  return (dispatch, getState) => {
    dispatch(isSearching());
    request('api/ticket/contract-statistics', 'POST', {
      body: JSON.stringify(searchParams)
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(reportAdminGameSuccess({
          pic: searchParams.email,
          response: response.payload
        }))
      } else {
        dispatch(reportAdminGameError(response.payload))
      }
    }).catch(function(err) {
      dispatch(reportAdminGameError({}))
    })
  }
}
export const reportAdminGameSuccess = (response) => {
  return {
    type: REPORT_ADMIN_GAME_REQUEST_SUCCESS,
    payload: response
  }
}

export const reportAdminGameError = (response) => {
  return {
    type: REPORT_ADMIN_GAME_REQUEST_ERROR,
    payload: response
  }
}

