
import {
  COLLABORATORS_REQUEST_ERROR,
  COLLABORATORS_REQUEST_SUCCESS,
  COLLABORATORS_FETCHING,
  GET_COLLABORATOR_BY_IDS_SUCCESS,
} from './actions'

const initialState = {
  collaborators: [],
  collaborator_by_ids: [],
  loadingCollaborators: false,
}

export default function collaboratorsReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    // GET_COLLABORATOR_BY_IDS_SUCCESS
    case GET_COLLABORATOR_BY_IDS_SUCCESS:
      return {
        ...state,
        collaborator_by_ids: payload?.payload?.collaborators,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case COLLABORATORS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingCollaborators: false,
        collaborators: payload,
      }
      break;
    case COLLABORATORS_REQUEST_ERROR:
      return {
        ...state,
        collaborators: [],
        loadingCollaborators: false,
        errorGlobal: ''
      }
      break;
    case COLLABORATORS_FETCHING:
      return {
        ...state,
        loadingCollaborators: true
      }
      break;
    default:
      return state
  }
}