import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react'
 
const ErrorMessage = ({message}) => {
  return (
    <div className="error-message-page">
      <div className="btn-lg btn-danger">
        <div className="error-message" dangerouslySetInnerHTML={{ __html: message }}>
        </div>
      </div>
    </div>
  );

}
export default ErrorMessage
