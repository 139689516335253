import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const TICKET_DETAIL_REQUEST = 'TICKET_DETAIL_REQUEST'
export const TICKET_DETAIL_REQUEST_ERROR = 'TICKET_DETAIL_REQUEST_ERROR'
export const TICKET_DETAIL_REQUEST_SUCCESS = 'TICKET_DETAIL_REQUEST_SUCCESS'
export const TICKET_DETAIL_FETCHING = 'TICKET_DETAIL_FETCHING'

export const UPDATE_TICKET = 'UPDATE_TICKET'
export const UPDATE_TICKET_ERROR = 'UPDATE_TICKET_ERROR'
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS'
export const UPDATING_TICKET = 'UPDATING_TICKET'

export const COMMENT = 'COMMENT'
export const COMMENT_ERROR = 'COMMENT_ERROR'
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS'
export const COMMENTING = 'COMMENTING'

export const REMOVEWATCHER = 'REMOVEWATCHER'
export const REMOVEWATCHER_ERROR = 'REMOVEWATCHER_ERROR'
export const REMOVEWATCHER_SUCCESS = 'REMOVEWATCHER_SUCCESS'
export const REMOVING_WATCHER = 'REMOVING_WATCHER'

export const ADDWATCHER = 'ADDWATCHER'
export const ADDWATCHER_ERROR = 'ADDWATCHER_ERROR'
export const ADDWATCHER_SUCCESS = 'ADDWATCHER_SUCCESS'
export const ADDING_WATCHER = 'ADDING_WATCHER'

export const ADD_FEEDBACK = 'ADD_FEEDBACK'
export const ADD_FEEDBACK_ERROR = 'ADD_FEEDBACK_ERROR'
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS'
export const ADDING_FEEDBACK = 'ADDING_FEEDBACK'

export const JOIN_CALLBACK_URL = 'https://gigi.garena.vn'

// ticket detail
export const isTicketDetailFetching = () => {
  return {
    type: TICKET_DETAIL_FETCHING
  }
}
export const getTicketDetail = (ticketId) => {
  return (dispatch, getState) => {
    dispatch(isTicketDetailFetching());
    request(`api/ticket/get-ticket-detail?ticket_id=${ticketId}`).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetailSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(getTicketDetailError(response.error_code))
      }
    }).catch(function (err) {
      dispatch(getTicketDetailError({}))
    })
  }
}
export const getTicketDetailSuccess = (response) => {
  return {
    type: TICKET_DETAIL_REQUEST_SUCCESS,
    payload: response
  }
}

export const getTicketDetailError = (response) => {
  return {
    type: TICKET_DETAIL_REQUEST_ERROR,
    payload: response
  }
}

// update ticket
export const isUpdatingTicket = () => {
  return {
    type: UPDATING_TICKET
  }
}
export const updateTicket = ({ user, ticketId, action, shoudRedirectAfterFinish }) => {
  return (dispatch, getState) => {
    dispatch(isUpdatingTicket());
    request(`api/user/update-ticket`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        action: action
      })
    }).then(async (response) => {
      if (response.status == 'successful') {
        dispatch(updateTicketSuccess(response.payload))
        if (!shoudRedirectAfterFinish) return false
        const nextTicket = response.next_ticket
        if (nextTicket) {
          if ([config.VU_CHI_CONG].includes(user.email)) {
            await Swal.fire({
              html: 'Ticket updated successfully',
              type: 'success',
              timer: 1000
            }).then(() => {
              window.location = `${config.url}/ticket/${nextTicket}`
            })
          } else {
            let confirmDialogResult = await Swal({
              title: 'Thành công',
              html: `Hệ thống GIGI tìm thấy 
              <a href="${config.url}/ticket/${nextTicket}"> ticket ${nextTicket}<a/>
              cần bạn phê duyệt`,
              type: 'success',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: `Tới ticket ${nextTicket}`,
              cancelButtonText: 'Lúc khác',
            })
            if (!confirmDialogResult.value) return
            window.location = `${config.url}/ticket/${nextTicket}`
          }
        } else {
          Swal.fire({
            html: 'Ticket updated successfully',
            type: 'success',
            timer: 1000
          })
        }
        if (['cancel', 'reopen'].includes(action)) {
          $('.comment-composer')[0].scrollIntoView();
        }
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(updateTicketError(response.error_code))
      }
    }).catch(function (err) {
      dispatch(updateTicketError({}))
    })
  }
}
export const updateTicketSuccess = (response) => {
  return {
    type: UPDATE_TICKET_SUCCESS,
    payload: response
  }
}

export const updateTicketError = (response) => {
  return {
    type: UPDATE_TICKET_ERROR,
    payload: response
  }
}

// comment
export const isCommenting = () => {
  return {
    type: COMMENTING
  }
}
export const comment = (formData) => {
  return (dispatch, getState) => {
    dispatch(isCommenting());
    request(`api/user/comment`, 'POST', {
      body: formData
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(commentSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(commentError(response.payload))
      }
    }).catch(function (err) {
      dispatch(commentError({}))
    })
  }
}
export const commentSuccess = (response) => {
  return {
    type: COMMENT_SUCCESS,
    payload: response
  }
}
export const commentError = (response) => {
  return {
    type: COMMENT_ERROR,
    payload: response
  }
}

// remove watcher
export const isRemovingWatcher = () => {
  return {
    type: REMOVING_WATCHER
  }
}
export const removeWatcher = ({ ticketId, watchers }) => {
  return (dispatch, getState) => {
    dispatch(isRemovingWatcher());
    request(`api/ticket/remove-watchers`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        watchers: watchers
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(removeWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(removeWatcherError(response.error_code))
      }
    }).catch(function (err) {
      dispatch(removeWatcherError({ err }))
    })
  }
}
export const removeWatcherSuccess = (response) => {
  return {
    type: REMOVEWATCHER_SUCCESS,
    payload: response
  }
}

export const removeWatcherError = (response) => {
  return {
    type: REMOVEWATCHER_ERROR,
    payload: response
  }
}

// add watcher
export const isAddingWatcher = () => {
  return {
    type: ADDING_WATCHER
  }
}
export const addWatcher = ({ ticketId, watchers }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/ticket/add-watchers`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        watchers: watchers
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: response.error_code
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}
export const addWatcherSuccess = (response) => {
  return {
    type: ADDWATCHER_SUCCESS,
    payload: response
  }
}

export const addWatcherError = (response) => {
  return {
    type: ADDWATCHER_ERROR,
    payload: response
  }
}

// remove reviewer
export const removeReviewer = ({ ticketId, reviewers }) => {
  return (dispatch, getState) => {
    dispatch(isRemovingWatcher());
    request(`api/ticket/remove-reviewers`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        reviewers: reviewers
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(removeWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(removeWatcherError(response.error_code))
      }
    }).catch(function (err) {
      dispatch(removeWatcherError({ err }))
    })
  }
}
// add reviewer
export const addReviewer = ({ ticketId, reviewers }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/ticket/add-reviewers`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        reviewers: reviewers
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

// assign task
export const assignTasks = ({ ticketId, tasks, shouldAddHistory, shouldNotifyAssignee }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/ticket/assign-tasks`, 'POST', {
      body: JSON.stringify({
        ticketId,
        tasks,
        shouldAddHistory,
        shouldNotifyAssignee
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

// assign task
export const updateTask = ({ ticketId, taskId, status }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/ticket/update-ticket-task`, 'POST', {
      body: JSON.stringify({
        ticketId,
        taskId,
        status
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

export const signContract = ({ ticketId, fileId, nickname }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/user/sign-contract`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        file_id: fileId,
        nickname,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

export const rejectContract = ({ ticketId, fileId, nickname }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/user/reject-contract`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        file_id: fileId,
        nickname,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getTicketDetail(ticketId))
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

export const notifyDigitalSignature = ({ ticketId, fileId }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/ticket/request-digital-signature`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        file_id: fileId,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        // dispatch(getTicketDetail(ticketId))
        Swal.fire({
          html: `Phòng Legal đã nhận được yêu cầu ký chữ ký số của bạn.<br/>
          Sau khi được TGĐ ký số, văn bản sẽ được uploaded lên ticket này`
        })
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

export const notifyFile = ({ ticketId, fileId, signers, urgent }) => {
  return (dispatch, getState) => {
    dispatch(isAddingWatcher());
    request(`api/user/notify-file`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        file_id: fileId,
        signers,
        urgent,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        // dispatch(getTicketDetail(ticketId))
        Swal.fire({
          html: `Gửi notify thành công`,
          type: 'success'
        })
        dispatch(addWatcherSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(addWatcherError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(addWatcherError({ err }))
    })
  }
}

export const deleteFile = ({ ticketId, fileId }) => {
  return (dispatch, getState) => {
    dispatch(isCommenting());
    request(`api/ticket/remove-file`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
        file_id: fileId,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        // dispatch(getTicketDetail(ticketId))
        Swal.fire({
          html: `File deleted successfully`,
          type: 'success'
        })
        dispatch(commentSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(commentError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(commentError({ err }))
    })
  }
}

export const getNextTicket = ({ ticketId }) => {
  return (dispatch, getState) => {
    dispatch(isCommenting());
    request(`api/user/get-next-ticket`, 'POST', {
      body: JSON.stringify({
        ticket_id: ticketId,
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        const nextTicket = response.payload?.next_ticket
        if (nextTicket) {
          window.location = `${config.url}/ticket/${nextTicket}`
        } else {
          window.location = `${config.url}/tickets`
          // Swal.fire({
          //   html: 'You have no pending approval ticket',
          //   type: 'success'
          // })
        }
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(commentError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(commentError({ err }))
    })
  }
}

export const addFeedback = ({ ticket_id, feedback_before, feedback_after, feedback_self }) => {
  return (dispatch, getState) => {
    dispatch(isCommenting());
    request(`api/ticket/feedback`, 'POST', {
      body: JSON.stringify({
        ticket_id, feedback_before, feedback_after, feedback_self
      })
    }).then(function (response) {
      if (response.status == 'successful') {
        // dispatch(getTicketDetail(ticketId))
        Swal.fire({
          html: `Feedback added successfully`,
          type: 'success'
        })
        dispatch(commentSuccess(response.payload))
      } else {
        Swal({
          type: 'error',
          html: lib.showError(response.error_code)
        })
        dispatch(commentError(response.error_code))
      }
    }).catch(function (err) {
      console.log('err', err)
      dispatch(commentError({ err }))
    })
  }
}