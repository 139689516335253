import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const COLLABORATORS_REQUEST         = 'COLLABORATORS_REQUEST'
export const COLLABORATORS_REQUEST_ERROR   = 'COLLABORATORS_REQUEST_ERROR'
export const COLLABORATORS_REQUEST_SUCCESS = 'COLLABORATORS_REQUEST_SUCCESS'
export const COLLABORATORS_FETCHING        = 'COLLABORATORS_FETCHING'

// get collaborators
export const isTicketsFetching = () => {
  return {
    type: COLLABORATORS_FETCHING,
  }
}
export const getContactUs = () => {
  return (dispatch, getState) => {
    dispatch(isTicketsFetching());
    request('api/metadata/get-infos').then(function(response) {
      dispatch(getContactUsSuccess(response.payload))
    }).catch(function(err) {
      dispatch(getContactUsError({}))
    })
  }
}
export const getContactUsSuccess = (response) => {
  return {
    type: COLLABORATORS_REQUEST_SUCCESS,
    payload: response
  }
}

export const getContactUsError = (response) => {
  return {
    type: COLLABORATORS_REQUEST_ERROR,
    payload: response
  }
}