import common from './common'
import formLabels from './formLabels'
import devOpts from './dev'
import productOpts from './product'
import contractNodes from './contractNodes'

let opts = __DEV__ ? devOpts : productOpts

export default {
  ...common,
  ...formLabels,
  ...contractNodes,
  ...opts
}