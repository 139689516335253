
import {
  MY_PENDING_SIGNATURE_SUCCESS,
  MY_PENDING_SIGNATURE_ERROR,
  MY_PENDING_SIGNATURE_FETCHING
} from './actions'

const initialState = {
  pendingSignatureFiles: [],
  loadingTickets: false,
}

export default function ticketsReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    case MY_PENDING_SIGNATURE_SUCCESS:
      return {
        ...state,
        loadingTickets: false,
        pendingSignatureFiles: payload.pending_files,
      }
      break;
    case MY_PENDING_SIGNATURE_ERROR:
      return {
        ...state,
        loadingTickets: false,
        pendingSignatureFiles: [],
        errorGlobal: ''
      }
      break;  
    case MY_PENDING_SIGNATURE_FETCHING:
      return {
        ...state,
        loadingTickets: true
      }
      break;  
    default:
      return state
  }
}