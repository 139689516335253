import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react'


 
const AccessDeniedView = (props) => {
  return (
    <div className="access-denied">
      <div className="access-denied-wrapper">
        <div className="top">
          <img src="/images/access-denied/access-denied.png" width="100%"></img>
        </div>
        <div className="error-message">
          Bạn không có quyền truy cập!
        </div>
      </div>  
    </div>
  );

}
export default AccessDeniedView
