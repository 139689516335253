
import {
  PROCESS_SUCCESS,
  PROCESS_ERROR,
  GET_CONFIDENCE_ERROR,
  GET_CONFIDENCE_SUCCESS,
  ARCHIVE_CONFIDENCE_ERROR,
  ARCHIVE_CONFIDENCE_SUCCESS,
  GET_CONFIDENCES_ERROR,
  GET_CONFIDENCES_SUCCESS,
  PROCESSING,
  SET_CONVERSATION
} from './actions'

const initialState = {
  isProcessingAction: false,
  conversation: null,
  inbox: [],
}

export default function askMeAnythingReducer(state = initialState, { type, payload } = action) {
  switch (type) {
    // send confidence
    case PROCESS_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case PROCESS_ERROR:
      return {
        ...state,
        isProcessingAction: false,
      }
      break;
    case PROCESSING:
      return {
        ...state,
        isProcessingAction: true
      }
      break;    

    // get confidence
    case GET_CONFIDENCE_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        conversation: payload,
        errorGlobal: ''
      }
      break;
    case GET_CONFIDENCE_ERROR:
      return {
        ...state,
        conversation: null,
        isProcessingAction: false,
      }
      break;

    // archice confidence
    case ARCHIVE_CONFIDENCE_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        errorGlobal: ''
      }
      break;
    case ARCHIVE_CONFIDENCE_ERROR:
      return {
        ...state,
        isProcessingAction: false,
      }
      break;  

    // get confidences
    case GET_CONFIDENCES_SUCCESS:
      return {
        ...state,
        isProcessingAction: false,
        inbox: payload,
        errorGlobal: ''
      }
      break;
    case GET_CONFIDENCES_ERROR:
      return {
        ...state,
        inbox: [],
        isProcessingAction: false,
      }
      break;  

    case SET_CONVERSATION:
      return {
        ...state,
        conversation: null,
        errorGlobal: ''
      }
      break;  

    default:
      return state
  }
}