import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react'
import cl from 'classnames'

const Alert = ({message, type, inlineStyle = {}}) => {
  return (
    <div className="alert-message-page">
      <div role='alert' 
        style={inlineStyle}
        className={cl('alert', 
          {'alert-danger': type == 'error'}, 
          {'alert-success': type  == 'success'},
          {'alert-warning': type  == 'warning'},
          )}>
        <div className="message" dangerouslySetInnerHTML={{ __html: message }}>
        </div>
      </div>
    </div>
  );

}
export default Alert
